import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Message } from './message.models';

export const MESSAGE_FEATURE_KEY = 'message';

export interface MessageState extends EntityState<Message> {
  loaded: boolean; // has the Message list been loaded
  error?: string | null; // last known error (if any)
}

export interface MessagePartialState {
  readonly [MESSAGE_FEATURE_KEY]: MessageState;
}

export const messageAdapter: EntityAdapter<Message> = createEntityAdapter<Message>();

export const initialMessageState: MessageState = messageAdapter.getInitialState({
  error: null,
  loaded: false,
});
