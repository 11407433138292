import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LegalInformationService } from '../utils/legal-information.service';

@Component({
  selector: 'legal-information',
  templateUrl: './legal-information.component.html',
  styleUrls: ['./legal-information.component.scss'],
})
export class LegalInformationComponent implements OnInit {
  type: string | null = '';
  legalInformation$: Promise<string> | undefined;

  constructor(
    private route: ActivatedRoute,
    private legalInformationService: LegalInformationService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      switch (params['type']) {
        case 'Datenschutzerklaerung':
          this.legalInformationService.datenschutzerklaerung.subscribe(
            (data) => {
              this.legalInformation$ = data.text();
            }
          );
          break;
        case 'Nutzungsbedingungen':
          this.legalInformationService.nutzungsbestimmungen.subscribe(
            (data) => {
              this.legalInformation$ = data.text();
            }
          );
          break;
        case 'Impressum':
          this.legalInformationService.impressum.subscribe((data) => {
            this.legalInformation$ = data.text();
          });
          break;
      }
    });
  }
}
