import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Participant } from './participant.models';

export const PARTICIPANT_FEATURE_KEY = 'participant';

export interface ParticipantState extends EntityState<Participant> {
  loaded: boolean; // has the Participant list been loaded
  error?: string | null; // last known error (if any)
  sortOrder: number;


}

export interface ParticipantPartialState {
  readonly [PARTICIPANT_FEATURE_KEY]: ParticipantState;
}

export const participantAdapter: EntityAdapter<Participant> = createEntityAdapter<Participant>();

export const initialParticipantState: ParticipantState = participantAdapter.getInitialState({
  error: null,
  loaded: false,
  sortOrder: 0,
});
