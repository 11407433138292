import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import "rxjs";
import { ConfigService } from '../utils/config/config.service';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({
  providedIn: 'root'
})
export class FileService {

  headers:HttpHeaders = new HttpHeaders().set('Application', 'application/json');

  constructor(private configService: ConfigService, private httpClient: HttpClient) {
  }

  uploadFile(conversationId: string, messageId: string, filename: string, formData: FormData) : Observable<any> {
    var url = `${this.configService.getConfig().chatApiBaseUrl}/me/chat/conversations/${conversationId}/messages/${messageId}/files/${filename}/content`;
    return this.httpClient.put(url, formData, {
      reportProgress: true,
      observe: 'events'
    })
  }


}
