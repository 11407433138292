import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';
import { ChatComponent } from './chat/chat/chat.component';
import { LegalInformationComponent } from './legal-information/legal-information.component';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'chat/',
  },
  {
    path: 'chat/:conversationId',
    component: ChatComponent,
    canActivate: [AutoLoginAllRoutesGuard],
  },
  {
    path: 'login-callback',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: 'logout',
    component: LogoutComponent,
    pathMatch: 'full',
    canActivate: [AutoLoginAllRoutesGuard],
  },
  { path: 'rechtsinformation', component: LegalInformationComponent },
  {
    path: '**',
    redirectTo: 'chat/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
