import { createFeatureSelector, createSelector } from '@ngrx/store';
import { participantAdapter, ParticipantState, PARTICIPANT_FEATURE_KEY } from './participant.state';
import * as ConversationSelectors from '../conversation/conversation.selectors';

// Lookup the 'Participant' feature state managed by NgRx
const getParticipantState = createFeatureSelector<ParticipantState>(PARTICIPANT_FEATURE_KEY);

const { selectAll, selectEntities, selectIds, selectTotal } = participantAdapter.getSelectors();

export const getParticipantError = createSelector(
  getParticipantState,
  (state: ParticipantState) => state.error
);

export const getAllParticipants = createSelector(getParticipantState, (state: ParticipantState) => {
  return selectAll(state);
});

export const getParticipantEntities = createSelector(getParticipantState, (state: ParticipantState) =>
  selectEntities(state)
);

// export const getAllParticipantsForSelectedConversation = createSelector(
//   getAllParticipants,
//   ConversationSelectors.getSelected,
//    (participants, selectedConversation) => {
//     if (selectedConversation == null)
//       return [];
//     return participants.filter( m => m.conversationId == selectedConversation.id);
//   }
// );


export const getParticipantsCount = createSelector(getParticipantState, selectTotal);

