import { Message } from "src/app/store/message/message.models";

export class MessageViewModel {

  id:string;
  conversationId:string;
  index:number;
  body: string;
  author: string;
  isFromMe: boolean;
  timestamp: Date;
  isRead: boolean = false;
  isReceived: boolean = false;
  type:string;
  twilioMessage: Message;
  creationDate: Date;

  file:FileViewModel;
  requestedDate:Date;

  get stateImageUrl():string {
    if (this.isReceived) {
      if (this.isRead) {
        return "/assets/double_check.png"
      } else {
        return "/assets/single_check.png"
      }
    } else {
      return "/assets/clock.png"
    }
  }

}

export class FileViewModel {

  filename: string;
}
