import { Component, OnInit, Input, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { AppState } from 'src/app/store/app.state';
import { select, Store } from '@ngrx/store';
import * as ConversationsSelectors from 'src/app/store/conversation/conversation.selectors'
import * as ConversationActions from 'src/app/store/conversation/conversation.actions';
import * as AuthActions from 'src/app/store/auth/auth.actions'
import * as AuthSelectors from 'src/app/store/auth/auth.selectors'
import * as MessageActions from 'src/app/store/message/message.actions';
import * as ChatSelectors from 'src/app/store/chat/chat.selectors'
import { map } from 'rxjs';
import { MessageViewModel } from '../../store/message/message.viewmodel';
import { ConfigService } from 'src/app/utils/config/config.service';
import { FileService } from '../file.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;
  @ViewChildren('item') itemElements: QueryList<any>;
  private scrollContainer: any;
  private isNearBottom:boolean = true;
  private isNearTop:boolean = false;

  conversationId: string | null = null;

  connectionStatus$ = this.store.pipe(select(ChatSelectors.getConnectionState));

  isSingleMode$ = this.store.pipe(select(ChatSelectors.getDisplayMode), map(_=>_ == 'single'));

  conversationSelected$ = this.store.pipe(select(ConversationsSelectors.getSelected));

  conversationTitle$ = this.store.pipe(select(ConversationsSelectors.getSelectedTitle));

  loadingMessages$ = this.store.pipe(select(ConversationsSelectors.getSelectedLoadingMessages));

  loggedInUser$ = this.store.pipe(select(AuthSelectors.selectUser));

  messages$ = this.store.pipe(select(ConversationsSelectors.getSelected), map(_ => {

    if (_.messages?.length == 0 || _.participants == null || _.participants?.length == 0)
      return [];

    let minLastReadIndex = _.participants.reduce((a, b) => a.lastReadMessageIndex < b.lastReadMessageIndex ? a : b ).lastReadMessageIndex;

    return _.messages.sort((a,b) => a.index - b.index).map(message => {

      let vm = new MessageViewModel();

        vm.id = message.id;
        vm.conversationId = message.conversationId;
        vm.twilioMessage = message;
        vm.index = message.index;
        vm.body = message.body;
        vm.author = message.author;
        vm.isFromMe = message.author == _.loggedInUser.id;
        vm.timestamp = message.creationDate;
        vm.creationDate = message.creationDate;

        vm.isReceived = true;
        vm.isRead = minLastReadIndex >= message.index;

        if (message.attributes.hasOwnProperty("type")) {
          vm.type = message.attributes["type"];
        }

        if (vm.type == 'file-request' && message.attributes.hasOwnProperty("file")) {
          vm.file = message.attributes["file"]
        }

      return vm;

    });

  }));

  messageText:string = "";
  profilePhotoBaseUrl:string;

  constructor(
    public store: Store<AppState>,
    private configService: ConfigService,
    private fileService: FileService) {

    this.profilePhotoBaseUrl = this.configService.getConfig().profilePhotoBaseUrl;

    this.store.pipe(select(ConversationsSelectors.getSelectedConversation)).subscribe(conversation => {
      this.conversationId = conversation?.id;
    });

    this.store.pipe(select(ConversationsSelectors.highliteMessageWithIndex)).subscribe(messageIndex => {
      if (messageIndex != null)
        setTimeout(() =>  document.getElementById("message-" + messageIndex).scrollIntoView()  ,10);
    });

  }

  logout() {
    console.log("sdlgkh");
    this.store.dispatch(AuthActions.signoutUser());
  }

  ngAfterViewInit() {
    this.scrollContainer = this.scrollFrame.nativeElement;
    this.itemElements.changes.subscribe(_ => this.onItemElementsChanged());
  }

  private isUserNearBottom(): boolean {
    const threshold = 150;
    const position = this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight;
    const height = this.scrollContainer.scrollHeight;
    return position > height - threshold;
  }

  private isUserNearTop(): boolean {
    return this.scrollContainer.scrollTop <= 0;
  }

  async scrolled(event: any) {
    this.isNearBottom = this.isUserNearBottom();
    this.isNearTop = this.isUserNearTop();
    if (this.isNearTop) {

      this.store.dispatch(MessageActions.loadMoreMessages( { conversationId: this.conversationId }));
    }
  }

  private onItemElementsChanged(): void {
    if (this.isNearBottom) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom(): void {
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      //behavior: 'smooth'
    });
  }

  ngOnInit(): void {
  }

  async sendMessageButtonClicked(event:any) {
    this.sendMessage(null);
  }

  async sendMessage(event:KeyboardEvent) {

    if (this.messageText.length === 0 || !this.messageText.trim())
      return;

    this.store.dispatch(ConversationActions.sendMessage( { conversationId: this.conversationId,  text: this.messageText}));

    this.messageText = "";
  }


  requestFile() {
    this.store.dispatch(ConversationActions.requestFile( { conversationId: this.conversationId }));
  }

  onFileSelected(event:any, message:MessageViewModel) {
    const file:File = event.target.files[0];
    if (file) {

      const formData = new FormData();
      formData.append("file", file);

      this.fileService.uploadFile(message.conversationId, message.id, file.name, formData).subscribe( response => {
        message.requestedDate = message.creationDate;
        message.file = {
          filename: file.name
        }
      });
    }
  }

  downloadFileClicked(message: MessageViewModel) {
  }
}




