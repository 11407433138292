import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AppConfiguration, ConfigService } from '../config/config.service';

@Injectable()
export class ApplicationInsightsService {
  appInsights: ApplicationInsights | null = null;
  appConfiguration!: AppConfiguration;
  enabled: boolean = false;

  constructor(private configService: ConfigService, private router: Router) {
    // createRouterSubscription
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe( (event : NavigationEnd | any)  => {
        this.logPageView(undefined, event.urlAfterRedirects);
    });
  }

  init() {
    if (this.configService.getConfig().appInsightsInstrumentationKey == null) {
      console.log(
        'No Application Insights Instrumentation Key found. Application Insights is disabled for this service.'
      );
      return;
    }
    this.enabled = true;
  }

  getAppInsights(): ApplicationInsights | null {
    if (!this.enabled) {
      return null;
    }

    if (this.appInsights != null) {
      return this.appInsights;
    }

    // https://docs.microsoft.com/de-de/azure/azure-monitor/app/javascript
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.configService.getConfig().appInsightsInstrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
        autoTrackPageVisitTime: true, // the time a user spends on each page is tracked
        enableCorsCorrelation: true,
      },
    });

    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();

    // loadCustomTelemetryProperties
    this.appInsights.addTelemetryInitializer((envelope) => {
      if (envelope !== undefined && envelope.tags !== undefined && this.appConfiguration != null) {
        envelope.tags['ai.cloud.role'] = this.configService.getConfig().appInsightsName;
      }
    });

    console.log('ApplicationInsightsService configured');
    return this.appInsights;
  }

  setUserId(userId: string) {
    this.getAppInsights()?.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.getAppInsights()?.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.getAppInsights()?.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.getAppInsights()?.trackEvent({ name: name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.getAppInsights()?.trackMetric(
      { name: name, average: average },
      properties
    );
  }

  logException(exception: Error, severityLevel?: number) {
    this.getAppInsights()?.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.getAppInsights()?.trackTrace({ message: message }, properties);
  }
}
