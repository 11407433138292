import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs';
import { AppState } from 'src/app/store/app.state';
import * as ChatSelectors from 'src/app/store/chat/chat.selectors';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit {

  hideConversations$ = this.store.pipe(
    select(ChatSelectors.getDisplayMode),
    map((_) => _ == 'single')
  );

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {}

}
