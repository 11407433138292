import { createAction, props } from '@ngrx/store';
import { Message } from '../message/message.models';
import { Conversation } from './conversation.models';


export const conversationJoined = createAction(
  '[Conversation] Joined',
  props<{ conversation: Conversation }>()
);

export const conversationLeft = createAction(
  '[Conversation] Left',
  props<{ conversationId: string }>()
);

export const markAllMessagesRead = createAction(
  '[Conversation] Mark all messages as read',
  props<{ conversationId: string }>()
);

export const markAllMessagesReadSuccess = createAction(
  '[Conversation] Mark all messages as read. Success',
);


export const sendMessage = createAction(
  '[Conversation] Send message',
  props<{ conversationId : string, text: string }>()
);

export const requestFile = createAction(
  '[Conversation] Request File',
  props<{ conversationId : string }>()
);

export const sendMessageSuccess = createAction(
  '[Conversation] Send message success',
  props<{ conversationId : string }>()
);


export const toggleSort = createAction(
  '[Conversation] Toggle Sort Order'
);
