import { ActionReducerMap } from '@ngrx/store';
import { AppState } from './app.state';
import { routerReducer } from '@ngrx/router-store';
import { conversationReducer } from './conversation/conversation.reducer';
import { chatReducer } from './chat/chat.reducer';
import { messageReducer } from './message/message.reducer';
import { participantReducer } from './participant/participant.reducer';
import { authReducer } from './auth/auth.reducer';


export const appReducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  chat: chatReducer,
  conversation: conversationReducer,
  message: messageReducer,
  participant: participantReducer,
  router: routerReducer,
};
