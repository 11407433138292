import { createAction, props } from '@ngrx/store';


export const connectionStateChanged = createAction(
  '[Chat] Connection State Changed',
  props<{ connectionState: boolean }>()
);

export const setDisplayMode = createAction(
  '[Chat] Set Display Mode',
  props<{ mode: string }>()
);
