import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import { AuthState } from './auth.state';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AuthState>,
    private oidcSecurityService: OidcSecurityService
  ) {

    // this.oidcSecurityService.getUserData().subscribe(
    //   (userData: any) => {
    //     if (userData == null)
    //       return;
    //     console.log("Signin User", userData);
    //       this.store.dispatch(AuthActions.signinSuccess({
    //           user: {
    //             id: userData.sub,
    //             name: userData.name,
    //             familyName: userData.family_name,
    //             givenName: userData.given_name,
    //           }
    //       }));
    //   });
  }

  public signoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signoutUser),
      switchMap(
        () => {
          console.log('Signout User');
          this.oidcSecurityService.logoff();

          return of(AuthActions.signoutUserSuccess());

        }
      )
    )
  );

}
