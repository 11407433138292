import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { AuthState, initialState } from './auth.state';

export const authReducer = createReducer(
  initialState,

  // on(AuthActions.loadAuths, (state) => ({ ...state, loading: true })),

  on(AuthActions.signinSuccess, (state, { user }) =>
  ({ ...state, user })
  ),

);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
