import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatState, CHAT_FEATURE_KEY } from './chat.state';

// Lookup the 'Chat' feature state managed by NgRx
const getChatState = createFeatureSelector<ChatState>(CHAT_FEATURE_KEY);

export const getConnectionState = createSelector(
  getChatState,
  (state: ChatState) => state.connectionState
);

export const getDisplayMode = createSelector(
  getChatState,
  (state: ChatState) => state.displayMode
);


