import { NgModule } from '@angular/core';
import { AuthModule, OpenIdConfiguration, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { CookieModule } from 'ngx-cookie';
import { map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';

export function configureAuth(configService: ConfigService) {

    const setupAction$ = configService.load()
      .pipe(
        map((config : any) => {
            const oidcConfig: OpenIdConfiguration = {
                clientId: config.clientId,
                authority: config.stsServer,
                responseType: config.responseType,
                redirectUrl: window.location.origin + config.redirectUrl,
                postLogoutRedirectUri: window.location.origin + config.postLogoutRedirectUri,
                scope: config.scope,
                silentRenew: config.silentRenew,
                logLevel: config.logLevel,
                useRefreshToken: config.useRefreshToken,
                renewTimeBeforeTokenExpiresInSeconds: config.renewTimeBeforeTokenExpiresInSeconds,
                secureRoutes: config.secureRoutes,
                postLoginRoute: config.postLoginRoute
            };
            return oidcConfig;
        })
    );

    return new StsConfigHttpLoader(setupAction$);
}

@NgModule({
    imports: [
        // CookieModule.withOptions(), // DO NOT Add this module. It will break the possibility to include the chat in everyday ui
        AuthModule.forRoot(
            {
              loader: {
                provide: StsConfigLoader,
                useFactory: configureAuth,
                deps: [ConfigService]
              }
            }
        )
    ],
    exports: [AuthModule],
})
export class AuthConfigModule {}
