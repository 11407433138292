import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TwilioDispatcherService } from 'src/app/twilio-dispatcher.service';
import * as ParticipantActions from './participant.actions';

@Injectable()
export class ParticipantEffects {

  constructor(
    private actions$: Actions,
    private twilioDispatcherService: TwilioDispatcherService
  ) {}

  // public markAllMessagesRead$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ParticipantActions.markAllMessagesRead),
  //     switchMap(
  //       (payload: { participantId: string }) => {
  //         console.log('Mark all messages read');

  //         this.twilioDispatcherService.markAllMessagesRead(payload.participantId);

  //         return of(ParticipantActions.markAllMessagesReadSuccess());

  //       }
  //     )
  //   )
  // );

  // public sendMessage$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ParticipantActions.sendMessage),
  //     switchMap(
  //       (payload: { participantId: string, text: string }) => {
  //         console.log('Send message to participant ' + payload.participantId,);

  //         this.twilioDispatcherService.sendMessage(payload.participantId, payload.text);

  //         return of(ParticipantActions.markAllMessagesReadSuccess());

  //       }
  //     )
  //   )
  // );

}
