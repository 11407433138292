import { Action, createReducer, on } from '@ngrx/store';
import * as ParticipantActions from './participant.actions';
import * as ConversationActions from '../conversation/conversation.actions';
import * as MessageActions from '../message/message.actions';
import { participantAdapter, ParticipantState, initialParticipantState } from './participant.state';
import { stat } from 'fs';

export const participantReducer = createReducer(
  initialParticipantState,

  on(ParticipantActions.addOrUpdateParticipants, (state, { participants }) => {
    //console.log("add or update participants reducer")
    return participantAdapter.upsertMany(participants, {
      ...state
    });
  }),

  on(ParticipantActions.setLastReadMessageIndex, (state, { participantId, lastReadMessageIndex }) => {
    // console.log("update last read message index reducer", lastReadMessageIndex);

    return participantAdapter.updateOne({
      id: participantId,
      changes: {
        ...state.entities[participantId],
        lastReadMessageIndex: lastReadMessageIndex
      }
    },state
    );
  }),

  on(MessageActions.addMessage, (state, { participantId, message }) => {

    return participantAdapter.updateOne({
      id: participantId,
      changes: {
        ...state.entities[participantId],
        lastReadMessageIndex: message.index
      }
    },state
    );
  }),

);

export function reducer(state: ParticipantState | undefined, action: Action) {
  return participantReducer(state, action);
}
