import { Action, createReducer, on } from '@ngrx/store';
import * as ChatActions from './chat.actions';
import { ChatState, initialState } from './chat.state';

export const chatReducer = createReducer(
  initialState,


  on(ChatActions.connectionStateChanged, (state, { connectionState }) =>
    ({ ...state, connectionState: connectionState })
  ),

  on(ChatActions.setDisplayMode, (state, { mode }) =>
    ({ ...state, displayMode: mode })
  ),

);

export function reducer(state: ChatState | undefined, action: Action) {
  return chatReducer(state, action);
}
