import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './chat/chat/chat.component';
import { ConversationsComponent } from './chat/conversations/conversations.component';
import { DialogComponent } from './chat/dialog/dialog.component';
import { LegalInformationComponent } from './legal-information/legal-information.component';
import { LogoutComponent } from './logout/logout.component';
import { appReducers } from './store/app.reducers';
import { AuthEffects } from './store/auth/auth.effects';
import { ConversationEffects } from './store/conversation/conversation.effects';
import { MessageEffects } from './store/message/message.effects';
import { ParticipantEffects } from './store/participant/participant.effects';
import { TwilioDispatcherService } from './twilio-dispatcher.service';
import { ApplicationInsightsService } from './utils/app-insights/appInsights.service';
import { AuthConfigModule } from './utils/auth/auth-config.module';
import { ConfigService } from './utils/config/config.service';
import { SecurePipe } from './utils/secure-pipe';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    ConversationsComponent,
    ChatComponent,
    DialogComponent,
    LogoutComponent,
    SecurePipe,
    LegalInformationComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AuthConfigModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([
      AuthEffects,
      ConversationEffects,
      ParticipantEffects,
      MessageEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ApplicationInsightsService,
    TwilioDispatcherService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService, ApplicationInsightsService],
      useFactory: (
        configService: ConfigService,
        appInsights: ApplicationInsightsService
      ) => {
        return async () => {
          // Init Configuration
          await configService.init();

          // Init Applications Insights
          appInsights.init();
        };
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
