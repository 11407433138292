import { Action, createReducer, on } from '@ngrx/store';
import * as ConversationActions from './conversation.actions';
import * as MessageActions from '../message/message.actions';
import { conversationAdapter, ConversationState, initialConversationState } from './conversation.state';

export const conversationReducer = createReducer(
  initialConversationState,

  on(ConversationActions.conversationJoined, (state, { conversation }) => {
    return conversationAdapter.addOne(conversation, {
      ...state
    });
  }),

  on(MessageActions.loadMoreMessages, (state, { conversationId }) => {
    return conversationAdapter.updateOne({
        id: conversationId,
        changes: {
          ...state.entities[conversationId],
          loadingMessages: true,
        }
      },state
    );
  }),

  on(MessageActions.loadMoreMessagesSuccess, (state, { conversationId, messages }) => {
    return conversationAdapter.updateOne({
      id: conversationId,
      changes: {
        ...state.entities[conversationId],
        loadingMessages: false,
        oldestMessageIndex: messages[messages.length-1]?.index ?? 0,
        highliteMessageWithIndex: messages[0]?.index+1 ?? null,
      }
    },state
    );
  }),

  on(MessageActions.loadMoreMessagesFailed, (state, { conversationId, error }) => {
    return conversationAdapter.updateOne({
      id: conversationId,
      changes: {
        ...state.entities[conversationId],
        loadingMessages: false,
      }
    },state
    );
  }),

  on(ConversationActions.conversationLeft, (state, { conversationId }) =>
    conversationAdapter.removeOne(conversationId, {
      ...state
    })
  ),

  on(ConversationActions.toggleSort, (state) => {
    console.log("Toggle Sort order")

    let sortOrder = state.sortOrder;
    sortOrder++;
    if (sortOrder >= 3)
    sortOrder = 0;

    return { ...state, sortOrder: sortOrder };
  }),


  on(MessageActions.addMessage, (state, { message }) => {

    console.log("add Message action reducer", message)
    const conversationId = message.conversationId;
    const messageId = message.index;

    console.log("Add Message action reducer: MessageID", messageId)

    return conversationAdapter.updateOne({
        id: conversationId,
        changes: {
          ...state.entities[conversationId],
          lastMessageIndex: messageId
        }
      },state
    );
  }),


);

export function reducer(state: ConversationState | undefined, action: Action) {
  return conversationReducer(state, action);
}
