import { Injectable, EventEmitter } from '@angular/core';
import { Client as TwilioConversationsClient } from '@twilio/conversations';
import { Conversation as TwilioConversation } from '@twilio/conversations/lib/conversation';
import { Participant as TwilioParticipant } from '@twilio/conversations/lib/participant';
import { Message as TwilioMessage } from '@twilio/conversations/lib/message';
import { Observable, BehaviorSubject, map, from, switchMap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import "rxjs";
import { ConfigService } from './utils/config/config.service';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.state';
import * as ConversationActions from './store/conversation/conversation.actions';
import * as MessageActions from './store/message/message.actions';
import * as ParticipantActions from './store/participant/participant.actions';
import * as ChatActions from './store/chat/chat.actions';
import { Message } from './store/message/message.models';
import { Participant } from './store/participant/participant.models';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({
  providedIn: 'root'
})
///
/// Dispatches events from Twilio into the store
///
export class TwilioDispatcherService {


  private twilioConversationsClient: TwilioConversationsClient;

  headers:HttpHeaders = new HttpHeaders().set('Application', 'application/json');

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    public store: Store<AppState>) {
  }

  init() {

    let token = this.getAccessToken().subscribe( async (jsonWebToken) => {
      this.twilioConversationsClient = await TwilioConversationsClient.create(jsonWebToken);


    // Emit MessageAdded
    this.twilioConversationsClient.on('messageAdded', async (twilioMessage: TwilioMessage) => {
      //console.info("## messageAdded", twilioMessage);

      const message: Message = this.toMessage(twilioMessage);
      this.store.dispatch(MessageActions.addMessage( { participantId: twilioMessage.participantSid, message: message }));
    });

    this.twilioConversationsClient.on('messageUpdated', async (event: any) => {
      //console.info("## messageUpdated", event.message);

      const message: Message = this.toMessage(event.message);
      this.store.dispatch(MessageActions.updateMessage( { message }));
    });

    this.twilioConversationsClient.on('messageRemoved', async (twilioMessage: TwilioMessage, updateReasons: any) => {
      //console.info("## messageRemoved", twilioMessage);
      const message: Message = this.toMessage(twilioMessage);
      this.store.dispatch(MessageActions.removeMessage( { message }));
    });

    // Emit ConversationLeft
    this.twilioConversationsClient.on("conversationLeft", (conversation : TwilioConversation) => {
      //console.info("## conversationLeft");
      this.store.dispatch(ConversationActions.conversationLeft( { conversationId: conversation.sid }));
    });

    // Emit ConnectionStateChanged TODO
    this.twilioConversationsClient.on("connectionStateChanged", (state) => {
      // console.log(state);

      switch (state) {
        case 'connected':
          this.store.dispatch(ChatActions.connectionStateChanged( { connectionState: true }));
          break;
        default:
          this.store.dispatch(ChatActions.connectionStateChanged( { connectionState: false }));
      }
    });

    // Emit ConversationJoined
    this.twilioConversationsClient.on("conversationJoined", async (twilioConversation : TwilioConversation) => {
      //console.info("## conversationJoined - friendlyName:" + twilioConversation.friendlyName + " - SID: " + twilioConversation.sid, twilioConversation);

      // Dispatch Participants
      let twilioParticipants = await twilioConversation.getParticipants();

      const participants: Participant[] = twilioParticipants.map(p => {
        const participant: Participant = {
          id: p.sid,
          conversationId: p.conversation.sid,
          geschaeftsstelle: {
            id: p.attributes['geschaeftsstelle']?.id,
            name: p.attributes['geschaeftsstelle']?.name,
          },
          name: p.attributes['name'],
          userId: p.identity,
          lastReadMessageIndex: p.lastReadMessageIndex
        };
        return participant;
      });
      this.store.dispatch(ParticipantActions.addOrUpdateParticipants( { participants: participants }));

      // Dispatch Messages
      let lastMessageIndex = 0;
      let oldestMessageIndex = 0;
      if (twilioConversation.lastMessage != null)
        lastMessageIndex = twilioConversation.lastMessage.index;
      let messagePaginator = await twilioConversation.getMessages(50);

      if (messagePaginator?.items?.length > 0) {
        oldestMessageIndex = messagePaginator.items.reduce((a, b) => a.index < b.index ? a : b).index
        const messages: Message[] = [];
        for (let i = 0; i < messagePaginator.items.length; i++) {
          var twilioMessage = messagePaginator.items[i];
          const message: Message = this.toMessage(twilioMessage);
          messages.push(message);
        }

        this.store.dispatch(MessageActions.setMessages( { messages: messages }));
      }

      // Dispatch Conversations
      this.store.dispatch(ConversationActions.conversationJoined( { conversation:
        {
          id: twilioConversation.sid,
          lastMessageIndex: lastMessageIndex,
          oldestMessageIndex: oldestMessageIndex,
          loadingMessages: false,
          highliteMessageWithIndex: null,
        }
      }));


    });

    // Emit ParticipantUpdated
    this.twilioConversationsClient.on("participantUpdated", async (event) => {
        let twilioParticipant:TwilioParticipant = event.participant;
        //console.info("## participantUpdated", twilioParticipant);
        this.store.dispatch(ParticipantActions.setLastReadMessageIndex( { participantId: twilioParticipant.sid, lastReadMessageIndex: twilioParticipant.lastReadMessageIndex }));
    });
  });
}


  sendMessage(conversationId: string, text: string) {
    //console.info("## send message<text>");
    if (this.twilioConversationsClient == null)
      return;
    this.twilioConversationsClient.getConversationBySid(conversationId).then( c => {
      c.sendMessage(text, { 'type': 'text' });
    });
  }

  requestFile(conversationId: string, text: string) {
    //console.info("## send message<file-request");
    if (this.twilioConversationsClient == null)
      return;
    this.twilioConversationsClient.getConversationBySid(conversationId).then( c => {
      c.sendMessage(text, { 'type': 'file-request' });
    });
  }

  async markAllMessagesRead(conversationId: string) : Promise<number> {
    if (this.twilioConversationsClient == null)
      return;
    this.twilioConversationsClient.getConversationBySid(conversationId).then( c => {
      //console.info("## mark all messages as read", c.sid);
      return c.setAllMessagesRead();
    });
  }

  loadMoreMessages(conversationId: string, pageSize:number, anchor:number): Observable<Message[]> {
    if (this.twilioConversationsClient == null)
      return null;

    return from(this.twilioConversationsClient.getConversationBySid(conversationId)).pipe(
      switchMap( conversation => {

        return from(conversation.getMessages(pageSize, anchor)).pipe(
          map( messagePaginator => {

            let messages : Message[] = [];
            for (let i = messagePaginator.items.length-1; i >= 0; i--) {
              var twilioMessage = messagePaginator.items[i];
              let message = this.toMessage(twilioMessage);
              messages.push(message);
            }
            return messages;

          }));
      }));

  }


  private getAccessToken(): Observable<string> {
    var url = [this.configService.getConfig().chatApiBaseUrl, 'me', 'chat', 'accessToken'].join('/');

    // console.log("http call: " + url);
    return this.httpClient.get<GetTokenResult>(url).pipe(
      map(result => {
        // console.log("Json Web Token: " + result.jsonWebToken)
        return result.jsonWebToken;
      })
    );
  }

  private toMessage(twilioMessage: TwilioMessage): Message {
    // console.log("toMessage", twilioMessage);
    return {
      id: twilioMessage.sid,
      index: twilioMessage.index,
      body: twilioMessage.body,
      author: twilioMessage.author,
      timestamp: twilioMessage.dateCreated,
      attributes: twilioMessage.attributes,
      creationDate: twilioMessage.dateCreated,
      type: twilioMessage.attributes["type"],
      conversationId: twilioMessage.conversation.sid,
    }
  }
}

export class GetTokenResult {
  jsonWebToken:string;
}

