import { User } from "./auth.models";

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  isLoggedIn: boolean; // Is the user logged in
  user: User | null; // ID of the logged in user
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const initialState: AuthState = {
  isLoggedIn: false,
  user: null,
};
