import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthState } from './store/auth/auth.state';
import * as AuthActions from './store/auth/auth.actions';
import { TwilioDispatcherService } from './twilio-dispatcher.service';
import { AppState } from './store/app.state';
import * as ChatActions from './store/chat/chat.actions';
import * as ChatSelectors from './store/chat/chat.selectors';
import { map } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy  {

  sub: any;

  title = 'Chat App';

  hideFooter$ = this.appStore.pipe(
    select(ChatSelectors.getDisplayMode),
    map((_) => _ != 'default')
  );


  constructor(
    private oidcSecurityService: OidcSecurityService,
    private twilioDispatcherService: TwilioDispatcherService,
    private authStore: Store<AuthState>,
    private appStore: Store<AppState>,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
      if (userData == null || isAuthenticated == false)
        return;

      //console.log("Signin User", userData);
      this.authStore.dispatch(AuthActions.signinSuccess({
          user: {
            id: userData.sub,
            name: userData.name,
            familyName: userData.family_name,
            givenName: userData.given_name,
          }
      }));
      // Init twilio
      this.twilioDispatcherService.init();
    });


    // http://localhost:4200/chat/?mode=single&user=bbb984b2-4710-4eee-9a39-5157b5d726a8

    this.sub = this.route.queryParams.subscribe((params) => {
      const mode = params['mode'] || 'default';
      if (mode != 'default') {
        this.appStore.dispatch(ChatActions.setDisplayMode({ mode: mode }));
      }
    });

  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

}
