import { createFeatureSelector, createSelector } from '@ngrx/store';
import { messageAdapter, MessageState, MESSAGE_FEATURE_KEY } from './message.state';

// Lookup the 'Message' feature state managed by NgRx
const getMessageState = createFeatureSelector<MessageState>(MESSAGE_FEATURE_KEY);

const { selectAll, selectEntities, selectIds, selectTotal } = messageAdapter.getSelectors();

export const getMessageError = createSelector(
  getMessageState,
  (state: MessageState) => state.error
);

export const getAllMessages = createSelector(getMessageState, (state: MessageState) =>
  selectAll(state)
);

export const getMessageEntities = createSelector(getMessageState, (state: MessageState) =>
  selectEntities(state)
);

export const getMessagesCount = createSelector(getMessageState, selectTotal);

