import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/utils/config/config.service';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import * as ConversationsSelectors from 'src/app/store/conversation/conversation.selectors'
import * as ConversationActions from 'src/app/store/conversation/conversation.actions';
import { Router } from '@angular/router';
import { Conversation } from 'src/app/store/conversation/conversation.models';
import * as ChatSelectors from 'src/app/store/chat/chat.selectors'
import { map } from 'rxjs';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.css'],
})
export class ConversationsComponent implements OnInit {

  connectionStatus$ = this.store.pipe(select(ChatSelectors.getConnectionState));

  conversations$ = this.store.pipe(select(ConversationsSelectors.getAllConversationsViewModel));

  selectedConversation$ = this.store.pipe(select(ConversationsSelectors.getSelected), map(_ => _.conversation));

  sortOrder$ = this.store.pipe(select(ConversationsSelectors.getSortOrder));

  profilePhotoBaseUrl:string;

  constructor(
    public store: Store<AppState>,
    private router: Router,
    private configService: ConfigService) {

    this.profilePhotoBaseUrl = this.configService.getConfig().profilePhotoBaseUrl;

    // Get messages for selected conversation
    this.store.pipe(
      select(ConversationsSelectors.getSelectedConversation)).subscribe(conversation => {
        if (conversation == null)
          return
        store.dispatch(ConversationActions.markAllMessagesRead({conversationId: conversation.id}))
    });
  }


  ngOnInit(): void {
  }


  sortToggle() {
    this.store.dispatch(ConversationActions.toggleSort());
  }

  onConversationClick(conversation : Conversation) {
    this.router.navigate(['/chat', conversation.id]);
  }

}
