<div class="dialog-container" [ngClass]="{'conversation-selected': conversationSelected$ | async}" >



    <div class="dialog-placeholder">
        <div class="dialog-placeholder-content">
            <img src="assets/empty-chat.png"/>
            <span>Wählen Sie einen Chat aus der Liste um mit einem externen Mitarbeiter zu chatten!</span>
        </div>
    </div>

    <div class="dialog-content">

        <div class="dialog-navbar">
            <div class="dialog-navbar-buttons"><button (click)="requestFile()"><img src="/assets/request-download.png"> Anfordern</button></div>

            <div *ngIf="(isSingleMode$ |async)" class="connection-status-container">
              <span *ngIf="(connectionStatus$ | async)" class="dot green" title="Verbunden"></span>
              <span *ngIf="!(connectionStatus$ | async)" class="dot red" title="Nicht verbunden"></span>
            </div>

            <div class="dialog-avatar dropdown" *ngIf="!(isSingleMode$ |async)">
              <img [ngClass]="{disconnected: !(connectionStatus$ | async)}"  *ngIf="loggedInUser$ | async as user" [src]="[profilePhotoBaseUrl, user.id, 'profile/photo/$value'].join('/') | secure | async">
              <div class="dropdown-content">
                <div *ngIf="loggedInUser$ | async as user">{{user.name}}</div>
                <div><button (click)="logout()">Abmelden</button></div>
              </div>
            </div>
        </div>

        <div id="chat-dialog" class="chat-dialog" #scrollframe (scroll)="scrolled($event)">
            <div class="chat-messages" >
                <div>

                    <div class="message-loader" [ngClass]="{'loading': loadingMessages$ | async}">Lade Nachrichten...</div>

                    <div *ngFor="let message of (messages$ | async); let i = index">

                        <div *ngIf="message.type == 'file-request' && message.isFromMe == false">
                            <!-- Display uploaded request messages (upload button) -->
                            <div *ngIf="message.file == null">
                                <div [attr.id]="'message-' + message.index" class="chat-message-container from-other {{message.type}}" #item>
                                    <button (click)="fileUpload.click()">
                                        <input (change)="onFileSelected($event, message)" #fileUpload type="file" style="display: none;" />
                                        <img src="/assets/file-upload.png"><span>Datei senden</span>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="message.file != null">
                                <div [attr.id]="'message-' + message.index" class="chat-message-container from-me {{message.type}}" #item>
                                    <div class="chat-message-inner-container">
                                        <div class="chat-message-content">  Du hast "{{message.file.filename}}" gesendet </div>
                                        <div class="chat-message-info">
                                            <span class="chat-message-timestemp"> {{message.timestamp.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}} | {{message.timestamp| date:'dd.MM.yyyy'}} </span>
                                            <span class="chat-message-delivery-state"><img [src]="message.stateImageUrl"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="(message.type == 'file-request' ) && message.isFromMe == true">
                            <div [attr.id]="'message-' + message.index" class="chat-message-container {{message.type}}" #item [ngClass]="{'from-me': message.isFromMe === true, 'from-other': message.isFromMe === false}">
                                <div class="chat-message-inner-container">
                                    <div class="chat-message-content">{{message.body}}</div>
                                    <div class="chat-message-info">
                                        <span class="chat-message-timestemp"> {{message.timestamp.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}} | {{message.timestamp | date:'dd.MM.yyyy'}} </span>
                                        <span class="chat-message-delivery-state"><img [src]="message.stateImageUrl"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="(message.type == 'file-request-upload') && message.isFromMe == false && false"> <!-- disabled -->
                            <div [attr.id]="'message-' + message.index" class="chat-message-container from-other {{message.type}}" #item>
                                <div style="display:block;">
                                    <button (click)="downloadFileClicked(message)" disabled>
                                        <img src="/assets/file-download.png"><span>Datei herunterladen</span>
                                    </button>
                                    <span class="chat-message-filename">{{message.file?.filename}}</span>
                                    <span class="chat-message-timestemp"> {{message.timestamp.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}} | {{message.timestamp | date:'dd.MM.yyyy'}}
                                         - Angefordert am {{message.requestedDate | date:'dd.MM.yyyy'}} </span>

                                </div>
                            </div>
                        </div>




                        <div *ngIf="message.type == 'text' || message.type == null">
                            <!-- Display standard text messages -->
                            <div [attr.id]="'message-' + message.index" class="chat-message-container {{message.type}}" #item [ngClass]="{'from-me': message.isFromMe === true, 'from-other': message.isFromMe === false}">
                                <div class="chat-message-inner-container">
                                    <div class="chat-message-content">{{message.body}}</div>
                                    <div class="chat-message-info">
                                        <span class="chat-message-timestemp"> {{message.timestamp.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}} | {{message.timestamp | date:'dd.MM.yyyy'}} </span>
                                        <span *ngIf="message.isFromMe" class="chat-message-delivery-state"> <img [src]="message.stateImageUrl"> </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </div>
        </div>

        <div class="chat-input">
            <input id="chat-input-box" placeholder="Hier tippen..." [(ngModel)]="messageText" type="text" (keydown.enter)="sendMessage($event)" />
            <button
                type="submit"
                (click)="sendMessageButtonClicked($event);"><img src="assets/button-send.png"></button>
        </div>

    </div>



</div>
