import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  // Api Config

  //public configUrl: string = '/api/configuration'; for SWA
  public configUrl: string = '/assets/config.json';
  private config: AppConfiguration | null = null;

  constructor(private http: HttpClient) {}

  getConfig(): AppConfiguration {
    return this.config!;
  }

  async init(): Promise<AppConfiguration> {
    this.config = await firstValueFrom(this.load());
    if (this.config == null) console.error('Failed to load configuration');
    return this.config;
  }

  load(): Observable<AppConfiguration> {
    const url = this.getConfigUrl();
    return this.http.get<AppConfiguration>(url);
  }

  private getConfigUrl(): string {
    return window.location.origin + this.configUrl;
  }
}

export class AppConfiguration {
  appInsightsInstrumentationKey: string | undefined;
  appInsightsName: string | undefined;
  stsServer: any;
  clientId: any;
  responseType: any;
  redirectUrl: any;
  scope: any;
  silentRenew: any;
  logLevel: any;
  postLogoutRedirectUri: any;
  useRefreshToken: any;
  postLoginRoute: any;
  eagerLoadAuthWellKnownEndpoints: any;
  secureRoutes: string[] | undefined;
  renewTimeBeforeTokenExpiresInSeconds: any;
  unternehmenApiBaseUrl: string | undefined;
  profilePhotoBaseUrl: string | undefined;
  chatApiBaseUrl: string | undefined;
  blobStorageUrl: string | undefined;
}
