
export const CHAT_FEATURE_KEY = 'chat';

export interface ChatState {
  connectionState: boolean; // Indicates if chat is connected to twilio
  userId: string | null; // ID of the logged in user
  displayMode: string | null;
}

export interface ChatPartialState {
  readonly [CHAT_FEATURE_KEY]: ChatState;
}

export const initialState: ChatState = {
  connectionState: false,
  userId: null,
  displayMode: 'default',
};
