import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MessageState } from '../message/message.state';
import { Conversation } from './conversation.models';

export const CONVERSATION_FEATURE_KEY = 'conversation';

export interface ConversationState extends EntityState<Conversation> {
  loaded: boolean; // has the Conversation list been loaded
  error?: string | null; // last known error (if any)
  sortOrder: number;
}

export interface ConversationPartialState {
  readonly [CONVERSATION_FEATURE_KEY]: ConversationState;
}

export const conversationAdapter: EntityAdapter<Conversation> = createEntityAdapter<Conversation>();

export const initialConversationState: ConversationState = conversationAdapter.getInitialState({
  error: null,
  loaded: false,
  sortOrder: 0,
});
