import { createAction, props } from '@ngrx/store';
import { User } from './auth.models';


export const signinUser = createAction(
  '[Auth] Signin User'
);

export const signinSuccess = createAction(
  '[Auth] Signin User Success',
  props<{ user: User }>()
);

export const signoutUser = createAction(
  '[Auth] Signout User'
);

export const signoutUserSuccess = createAction(
  '[Auth] Signout User Success'
);
