import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config/config.service';

@Injectable({ providedIn: 'root' })
export class LegalInformationService {
  constructor(private config: ConfigService, private http: HttpClient) {}

  get datenschutzerklaerung() {
    const url = [
      this.config.getConfig().blobStorageUrl,
      'datenschutz.html',
    ].join('/');
    return this.http.get(url, {
      responseType: 'blob',
    });
  }

  get nutzungsbestimmungen() {
    const url = [this.config.getConfig().blobStorageUrl, 'agb.html'].join('/');
    return this.http.get(url, {
      responseType: 'blob',
    });
  }

  get impressum() {
    const url = [this.config.getConfig().blobStorageUrl, 'impressum.html'].join(
      '/'
    );
    return this.http.get(url, {
      responseType: 'blob',
    });
  }
}
