import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, withLatestFrom, EMPTY, from  } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { TwilioDispatcherService } from 'src/app/twilio-dispatcher.service';
import * as ConversationActions from './conversation.actions';
import * as ParticipantActions from '../participant/participant.actions';
import * as ConversationSelectors from './conversation.selectors';
import * as MessageActions from '../message/message.actions';
import { Store } from '@ngrx/store';
import { Conversation } from './conversation.models';
import { ActivatedRoute, Router } from '@angular/router';
import { Participant } from '../participant/participant.models';

@Injectable()
export class ConversationEffects {

  constructor(
    private actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private conversationStore: Store<Conversation>,
    private twilioDispatcherService: TwilioDispatcherService
  ) {}

  public conversationJoined$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantActions.addOrUpdateParticipants),
      map((payload: { participants:Participant[] }) => {

        this.route.queryParams.subscribe(params => {
          const user = params['user'] || null
          const mode = params['mode'] || 'default';

          if (user != null) {

            let participants = payload.participants.filter(p => p.userId == user);
            console.log('dslfhdsöghdskgjhdslkgjhdskgjhdskgjhdskjghdskghdksjghlk', participants)
            if (participants?.length > 0) {
              this.router.navigate(['/chat', participants[0].conversationId], { queryParams: {mode: mode}});
            }

          }

        });



      })
    ),
    {
      dispatch: false
     }
  );

  public markAllMessagesRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConversationActions.markAllMessagesRead),
      switchMap(
        (payload: { conversationId: string }) => {
          // console.log('Mark all messages read', payload.conversationId);

          this.twilioDispatcherService.markAllMessagesRead(payload.conversationId);

          return of(ConversationActions.markAllMessagesReadSuccess());

        }
      )
    )
  );

  public loadMoreMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessageActions.loadMoreMessages),
      withLatestFrom(this.conversationStore.select(ConversationSelectors.getSelected)),
      switchMap(
        ([action, c]) => {
          return from(this.twilioDispatcherService.loadMoreMessages(action.conversationId, 50, c.conversation.oldestMessageIndex-1)).pipe(
            map( (messages)  => {
              return MessageActions.loadMoreMessagesSuccess({ conversationId: action.conversationId, messages: messages })
            }),
            catchError((error: HttpErrorResponse) =>
              of(MessageActions.loadMoreMessagesFailed({ conversationId: action.conversationId, error }))
            )
          )
        }
      )
    )
  );


  public onMessageAddedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessageActions.addMessage),
      withLatestFrom(this.conversationStore.select(ConversationSelectors.getSelectedConversation)),
      switchMap(
        ([action, selectedConversation]) => {

          // console.log('Mark all messages read for selected conversation', selectedConversation);

          if (action.message.conversationId != selectedConversation.id)
            return of(ConversationActions.markAllMessagesReadSuccess());

          this.twilioDispatcherService.markAllMessagesRead(action.message.conversationId);

          return of(ConversationActions.markAllMessagesReadSuccess());

        }
      )
    )
  );

  public sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConversationActions.sendMessage),
      switchMap(
        (payload: { conversationId: string, text: string }) => {
          this.twilioDispatcherService.sendMessage(payload.conversationId, payload.text);
          this.twilioDispatcherService.markAllMessagesRead(payload.conversationId);
          return of(ConversationActions.sendMessageSuccess( {conversationId: payload.conversationId }));
        }
      )
    )
  );



  public requestFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConversationActions.requestFile),
      switchMap(
        (payload: { conversationId: string }) => {
          this.twilioDispatcherService.requestFile(payload.conversationId, 'Sie haben eine Datei angefordert');
          this.twilioDispatcherService.markAllMessagesRead(payload.conversationId);
          return of(ConversationActions.sendMessageSuccess( {conversationId: payload.conversationId }));
        }
      )
    )
  );



}
