import { Action, createReducer, on } from '@ngrx/store';
import * as MessageActions from './message.actions';
import { messageAdapter, MessageState, initialMessageState } from './message.state';

export const messageReducer = createReducer(
  initialMessageState,

  on(MessageActions.setMessages, (state, { messages }) =>
    messageAdapter.addMany(messages, {
      ...state,
    })
  ),

  on(MessageActions.loadMoreMessagesSuccess, (state, { conversationId, messages }) =>
    messageAdapter.addMany(messages, {
      ...state,
    })
  ),

  on(MessageActions.updateMessage, (state, { message }) =>
    messageAdapter.upsertOne(message, {
      ...state,
    })
  ),

  on(MessageActions.removeMessage, (state, { message }) =>
    messageAdapter.removeOne(message.id, {
      ...state
    })
  ),

  on(MessageActions.addMessage, (state, { message }) =>
    messageAdapter.addOne(message, {
      ...state
    })
  ),

  on(MessageActions.removeMessage, (state, { message }) =>
    messageAdapter.removeOne(message.id, {
      ...state
    })
  ),

);

export function reducer(state: MessageState | undefined, action: Action) {
  return messageReducer(state, action);
}
