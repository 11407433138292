import { createAction, props } from '@ngrx/store';
import { Message } from './message.models';


export const setMessages = createAction(
  '[Message] Set Messages',
  props<{ messages: Message[] }>()
);

export const addMessage = createAction(
  '[Message] Add Message',
  props<{ participantId: string, message: Message }>()
);

export const updateMessage = createAction(
  '[Message] Updated Message',
  props<{ message: Message }>()
);

export const removeMessage = createAction(
  '[Message] Remove Messasge',
  props<{ message: Message }>()
);

export const loadMoreMessages = createAction(
  '[Message] Load More Messages',
  props<{ conversationId : string }>()
);

export const loadMoreMessagesSuccess = createAction(
  '[Message] Load More Messages Success',
  props<{ conversationId : string, messages: Message[] }>()
);

export const loadMoreMessagesFailed = createAction(
  '[Message] Load More Messages Failed',
  props<{ conversationId : string, error: Error }>()
);
