import { createFeatureSelector, createSelector } from '@ngrx/store';
import { conversationAdapter, ConversationState, CONVERSATION_FEATURE_KEY } from './conversation.state';
import * as AuthSelectors from '../auth/auth.selectors';
import * as ParticipantSelectors from '../participant/participant.selectors';
import * as MessageSelectors from '../message/message.selectors';
import * as RouterSelectors from '../router.selectors';
import { Conversation } from './conversation.models';
import { ConversationViewModel } from './conversation.viewmodels';
import { title } from 'process';

// Lookup the 'Conversation' feature state managed by NgRx
const getConversationState = createFeatureSelector<ConversationState>(CONVERSATION_FEATURE_KEY);

const { selectAll, selectEntities, selectIds, selectTotal } = conversationAdapter.getSelectors();

export const getConversationError = createSelector(
  getConversationState,
  (state: ConversationState) => state.error
);

export const getAllConversations = createSelector(getConversationState, (state: ConversationState) => {
  // console.log("getAllConversations");
  return selectAll(state)
});

export const getConversationEntities = createSelector(getConversationState, (state: ConversationState) =>
  selectEntities(state)
);

export const getSortOrder = createSelector(
  getConversationState,
  (state: ConversationState) => state.sortOrder
);

export const getSelectedConversation = createSelector(
  RouterSelectors.selectRouteNestedParams,
  getConversationEntities,
  ({ conversationId }, conersations): Conversation | null => {
    if (conversationId == null)
      return null;
    return conersations[conversationId];
  }
);

export const getSelected = createSelector(
  AuthSelectors.selectUser,
  RouterSelectors.selectRouteNestedParams,
  getConversationEntities,
  ParticipantSelectors.getAllParticipants,
  MessageSelectors.getAllMessages,
  (loggedInUser, { conversationId }, conversations, participants, messages) => {

    if (loggedInUser == null || conversationId == null)
      return {};

    const selectedConversation = conversations[conversationId];
    const filteredParticipants = participants.filter(p => p.conversationId == conversationId);
    const filteredMessage = messages.filter(p => p.conversationId == conversationId);

    return {
      loggedInUser: loggedInUser,
      conversation: selectedConversation,
      participants: filteredParticipants,
      messages: filteredMessage,
    }
  });




// export const getSelected = createSelector(
//   getConversationEntities,
//   RouterSelectors.selectRouteNestedParams,
//   (entities,  { chatId }): Conversation | undefined => {

//     console.log("get selected conversation: ", chatId)

//     if (entities == null || chatId == null)
//       return undefined;

//     return entities[chatId];
//   }
// );

export const getSelectedTitle = createSelector(
  getSelected,
  (selected): string | null => {
    if (selected.conversation == null)
      return null;

    const filteredParticipants = selected.participants.filter(p => p.userId != selected.loggedInUser.id);
    if (filteredParticipants.length > 0) {
      return filteredParticipants[0].name;
    }
    return null
  }
);

export const highliteMessageWithIndex = createSelector(
  getSelectedConversation,
  (selected): number | null => {
    if (selected == null)
      return null;

    return selected.highliteMessageWithIndex;
  }
);


export const getSelectedLoadingMessages = createSelector(
  getSelectedConversation,
  (selected): boolean => {

    if (selected == null)
      return null;

    return selected.loadingMessages;
  }
);

export const getAllConversationsViewModel = createSelector(
  AuthSelectors.selectUser,
  getAllConversations,
  ParticipantSelectors.getAllParticipants,
  getSortOrder,
  (loggedInUser, conversations, participants, sortOrder) => {

    if (loggedInUser == null || conversations == null || participants?.length == 0)
      return [];


    let conversationViewModels = conversations.map( con => {

      const participantsMe = participants.filter(p => p.conversationId == con.id && p.userId == loggedInUser.id);

      const participantsPartner = participants.filter(p => p.conversationId == con.id && p.userId != loggedInUser.id);
      let title: string | null = null;
      let subtitle: string | null = null;
      let userId: string | null = null;

      if (participantsPartner.length > 0) {
        const participant = participantsPartner[0];
        title = participant.name;
        subtitle = participant.geschaeftsstelle?.name;
        userId = participant.userId;
      }

      let unreadMessageCount: number = 0;
      if (participantsMe.length > 0) {
        const participant = participantsMe[0];
        unreadMessageCount = con.lastMessageIndex - participant.lastReadMessageIndex;
      }


      const vm: ConversationViewModel = {
        id: con.id,
        title: title,
        subtitle: subtitle,
        isSelected: false,
        photoId: userId,
        unreadMessageCount: unreadMessageCount,
      };

      return vm;

    });
    //console.log("Conversation View Model: ", conversationViewModels)

    switch (sortOrder) {
      case 0:
        conversationViewModels.sort((a,b) => a.title?.localeCompare(b.title));
        break;
      case 1:
        conversationViewModels.sort((a,b) => -a.title?.localeCompare(b.title));
        break;
      case 2:
        conversationViewModels.sort((a,b) => +b.unreadMessageCount - +a.unreadMessageCount);
        break;
    }

    return conversationViewModels;
  }
);




export const getConversationsCount = createSelector(getConversationState, selectTotal);

