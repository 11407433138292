import { createAction, props } from '@ngrx/store';
import { Participant } from './participant.models';

export const addOrUpdateParticipants = createAction(
  '[Participant] Add Or Update Participants',
  props<{ participants: Participant[] }>()
);


export const setLastReadMessageIndex = createAction(
  '[Participant] Update Last Read Message Index',
  props< { participantId: string, lastReadMessageIndex: number }>()
);
