<div class="conversations-container">

    <div class="conversation-title">
      <span *ngIf="(connectionStatus$ | async)" class="dot green" title="Verbunden"></span>
      <span *ngIf="!(connectionStatus$ | async)" class="dot red" title="Nicht verbunden"></span>
      <span class="conversation-title-header">Zeitarbeitnehmer</span>
      <button (click)="sortToggle()" class="sort-button"><img src="/assets/sort.png"></button>
    </div>

    <table>
        <tr
          class="conversation" *ngFor="let conversation of (conversations$ | async); let i = index"
          (click)="onConversationClick(conversation)"
          [ngClass]="{'conversation-selected': conversation.id == (selectedConversation$ | async)?.id}">
            <td class="conversation-icon"><img [src]="[profilePhotoBaseUrl, conversation.photoId, 'profile/photo/$value'].join('/') | secure | async"></td>
            <td class="conversation-text">
                <div class="title">{{conversation.title ?? "Unbekannt"}}</div>
                <div class="subtitle">Niederlassung: {{conversation.subtitle ?? "Unbekannt"}}</div>
            </td>
            <td class="conversation-unread-message-count">
                <span *ngIf="conversation.unreadMessageCount && conversation.id != (selectedConversation$ | async)?.id" class="conversation-unread-message-count-icon">{{conversation.unreadMessageCount}}</span>
            </td>



        </tr>
    </table>
</div>
